<template>
  <div class="a-slide__details">
    <div class="a-slide__details-container">
      <div class="a-slide__details-left">
        <!-- <span class="smallTitle">wir empfehlen</span> -->
        <div class="mainSelection" v-if="state && state.tariff">
          <span class="tariff">
            Tarif
          </span>
          <div class="optionWrapper">
            <div class="h3" v-text="state.tariff.fullName"></div>
            <p v-if="state.tariff.description && state.tariff.description !== ''" v-text="state.tariff.description"></p>
            <p v-else>> inkl.
              <template v-for="(pckg, i) in includedPackages">
                <span :key="'pckg-incl-' + i" v-text="(pckg.description && pckg.description !== '' ? pckg.description : pckg.name) + (includedPackages[i+1] ? ', ' : '')"></span>
              </template>
            </p>
            <span class="price"><span v-text="state.tariff.price.toLocaleString().replace(/\./g, ',')"></span> € <small>mtl.</small></span>
          </div>
        </div>
        <div class="mainSelection secondary" v-for="(pkg, i) in visiblePackages" :key="'pckg-vis-' + i">
          <span class="tariff" v-text="pkg.description + ' OPTIONS'"></span>
          <div class="optionWrapper" >
            <div class="h3" v-text="pkg.name"></div>
            <!-- <p v-if="pkg.description" v-text="pkg.description"></p> -->
            <span class="price"><span>0</span> € <small>mtl.</small></span>
          </div>
        </div>
        <div class="mainSelection secondary"  v-if="state && (state.package || (state.addons && state.addons.length))">
          <span class="tariff" v-if="state && (state.package || (state.addons && state.addons.length))">
            zubuchoptionen
          </span>
          <div class="optionWrapper" v-if="state && state.package">
            <div class="h3" v-text="state.package.name"></div>
            <p v-if="state.package && state.package.description" v-text="state.package.description"></p>
            <p v-else>> inkl.
              <template v-for="(pckg, i) in subPackages">
                <span :key="'pckg-sub-' + i" v-text="(pckg.description ? pckg.description : pckg.name) + (subPackages[i+1] ? ', ' : '')"></span>
              </template>
            </p>
            <span class="price">{{(state.package.price - state.package.discount_24).toLocaleString().replace(/\./g, ',')}} € <small>mtl.</small></span>
          </div>
          <div class="optionWrapper" v-if="state && state.addons && state.addons.length">
            <div class="h3">
              <div>
                <template v-for="(addon, i) in stateAddons">
                  {{ addon.name }} <span :key="'state-addon-' + i" v-text="'('+ filterPrice(addon) +'€)' + (stateAddons[i + 1] ? ', ' : '')"></span>
                </template>
              </div>
            </div>
            <span class="price"><span v-text="totals.addons.toLocaleString().replace(/\./g, ',')"></span> € <small>mtl.</small></span>
          </div>
        </div>
        <template v-if="state && state.tariff && state.packageSuboption && Object.keys(state.packageSuboption).length">
          <div class="mainSelection secondary" v-for="(subItm, i) in state.packageSuboption" :key="'sub-itm-tr' + i + subItm">
            <span class="tariff" >
              {{ subItm.name.split(' ')[0] }} options
            </span>
            <div class="optionWrapper">
              <div class="h3" v-text="subItm.name"></div>
              <p v-if="subItm.description" v-text="subItm.description"></p>
              <span class="price"><span v-text="parseFloat(subItm.price).toLocaleString().replace(/\./g, ',')"></span> € <small>mtl.</small></span>
            </div>
          </div>
        </template>
        <!-- <div class="mainSelection secondary" v-if="state && state.tariff && state.packageSuboption && state.packageSuboption.price > 0">
          <span class="tariff">
            Netflix-options
          </span>
          <div class="optionWrapper">
            <div class="h3" v-text="state.packageSuboption.name"></div>
            <p v-if="state.packageSuboption.description" v-text="state.packageSuboption.description"></p>
            <span class="price"><span v-text="parseFloat(state.tariff.render_unique && state.packageSuboption.has_unique_price && state.packageSuboption.is_unique && state.packageSuboption.unique_price ? state.packageSuboption.unique_price : state.packageSuboption.price).toLocaleString().replace(/\./g, ',')"></span> € <small>mtl.</small></span>
          </div>
        </div> -->
        <div class="mainSelection secondary mt-auto" v-if="state.tv_action && (state.tariff[state.tv_action === 1 ? ('nk' + activeDiscount) : ('bk' + activeDiscount)] !== false) ">
          <span class="tariff">
            AKTION
          </span>
          <div class="optionWrapper">
            <div class="h3" v-text="state.tariff[state.tv_action === 1 ? ('nk' + activeDiscount + '_description') : ('bk' + activeDiscount + '_description')]"></div>
            <span class="price">- <span v-text="state.tariff[state.tv_action === 1 ? ('nk' + activeDiscount) : ('bk' + activeDiscount)].toLocaleString().replace(/\./g, ',')"></span> € <small>mtl.</small></span>
          </div>
        </div>
        <div class="finalPrice">
          <div>
            <a v-if="offerButton && details[0] && details[0].button_action && details[0].button_text"
            :href="details[0].button_action"
            v-text="details[0].button_text"
            target="_blank" class="btn"></a>
          </div>
          <div class="totalRightSide">
            <span class="payPrice">
              <span v-text="totals.total.toLocaleString().replace(/\./g, ',') + ' €'"></span>
              <small> mtl.</small>
            </span>
            <span class="actionDescription" v-if="state.tv_action">
              <span
              v-if="(state.tariff[state.tv_action === 1 ? 'nk_12' : 'bk_12'])"
              v-text="discountPrices('12') + ((state.tariff[state.tv_action === 1 ? 'nk_24' : 'bk_24']) ? ',  ' : '')"></span>
              <span
              v-if="(state.tariff[state.tv_action === 1 ? 'nk_24' : 'bk_24'])"
              v-text="discountPrices('24') + ((state.tariff[state.tv_action === 1 ? 'nk_12' : 'bk_12']) ? '' : ', ')"></span>
              <span
              v-if="(state.tariff[state.tv_action === 1 ? ('nk' + this.activeDiscount) : ('bk' + this.activeDiscount)])
              && !(state.tariff[state.tv_action === 1 ? 'nk_24' : 'bk_24'])
              && !(state.tariff[state.tv_action === 1 ? 'nk_12' : 'bk_12'])"
              v-text="discountPrices(state.tariff['active_'+ (state.tv_action === 1 ? 'nk' : 'bk') + '_discount'] || '6')"></span>
             </span>
          </div>
        </div>
      </div>
      <div class="a-slide__details-right">
        <div class="a-slide__details-right--top rightRow">
          <div class="stickyTitle">ERSPARNIS</div>
          <div class="stickyPrice">
            <span v-text="totals.monthTotalSave.toLocaleString().replace(/\./g, ',')"></span> € <small> mtl.</small>
          </div>
        </div>
        <div class="a-slide__details-right--mid rightRow">
          <div class="firstInfo">
            <div class="stickyPrice">
              <span v-text="totals.yearTotalSave.toLocaleString().replace(/\./g, ',')"></span> € <small v-text="details[0].details_24 || 'gesamt in 24 Monaten'"></small>
            </div>
          </div>
          <div class="secInfo">
            <div class="stickyPrice">
              <span v-text="totals.totalAfter.toLocaleString().replace(/\./g, ',') + '  € mtl. ' + (details[0].details_after || '(Nach 24 Monaten)')"></span>
            </div>
          </div>
        </div>
        <div class="a-slide__details-right--bottom rightRow">
          <div class="stickyTitle">MARKTPREIS</div>
          <div class="stickyPrice"><span v-text="totals.marketprice.toLocaleString().replace(/\./g, ',')"></span> € <small> mtl.</small></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    state: {
      type: Object,
      default: null
    },
    packages: {
      type: Array,
      default: null,
      required: true
    },
    baseUrl: {
      type: String
    },
    tooltip: {
      type: String,
      default: null
    },
    details: {}
  },
  computed: {
    includedPackages () {
      return this.packages.filter((item) => item.tariffs && item.tariffs?.includes(this.state.tariff.id) && !item.is_sub_option)
    },
    subPackages () {
      return this.packages.filter(item => item.parent_id === this.state.package?.id)
    },
    stateAddons () {
      // TODO: check child packages
      return this.packages.filter(item => this.state.addons.includes(item.id))
    },
    offerButton () {
      return this.$route?.query?.offer !== 'hide'
    },
    activeDiscount () {
      return this.state.tariff['active_' + (this.state.tv_action === 1 ? 'nk' : 'bk') + '_discount'] === '3' ? '_3' : ''
    },
    visiblePackages () {
      return this.packages?.filter(item => item.show_on_details === '1' && item.tariffs?.includes(this.state.tariff.id)) || []
    }
  },
  watch: {
    state: {
      deep: true,
      handler: function (newVal) {
        newVal && this.calcPrices()
      }
    }
  },
  methods: {
    filterPrice (item, after = false) {
      return parseFloat(item.price) - parseFloat(after ? item.discount_after : item.discount_24)
    },
    tariffTotal () {
      switch (this.state.tv_action) {
        case 1:
          return this.state.tariff.price - this.state.tariff['nk' + this.activeDiscount]
        case 2:
          return this.state.tariff.price - this.state.tariff['bk' + this.activeDiscount]
        default:
          return this.state.tariff.price
      }
    },
    calcPrices () {
      this.totals = {
        marketprice: 0,
        total: this.tariffTotal(),
        addons: 0,
        monthTotalSave: 0,
        yearTotalSave: 0,
        totalAfter: 0
      }

      for (let i = 0; i < this.includedPackages.length; i++) {
        this.totals.marketprice += this.includedPackages[i].price
      }

      for (let j = 0; j < this.stateAddons.length; j++) {
        this.totals.marketprice += this.stateAddons[j].price
        this.totals.total += this.filterPrice(this.stateAddons[j])
        this.totals.addons += this.filterPrice(this.stateAddons[j])
        this.totals.totalAfter += this.filterPrice(this.stateAddons[j], true)
      }

      // TODO: change package suboption to array
      for (let k = 0; k < this.state.packageSuboption.length; k++) {
        // debugger
        this.totals.total += this.state.packageSuboption[k].price
        this.totals.marketprice += this.state?.packageSuboption[k]?.market_price
        this.totals.totalAfter += this.state?.packageSuboption[k]?.price
      }

      // if (Object.keys(this.state.packageSuboption).length) {
      //   for (let item in this.state.packageSuboption) {
      //     console.log(this.state.packageSuboption[item])
      //     this.totals.total += this.state.packageSuboption[item].price
      //     this.totals.marketprice += this.state?.packageSuboption[item]?.price
      //   }
      //   // this.totals.total += this.state.tariff.render_unique && this.state.packageSuboption.has_unique_price && this.state.packageSuboption.is_unique && this.state.packageSuboption.unique_price ? this.state.packageSuboption.unique_price : this.state.packageSuboption.price
      // }

      this.totals.marketprice += this.state?.package?.price || 0
      this.totals.total += this.state?.package ? this.filterPrice(this.state.package) : 0

      this.totals.totalAfter += this.state?.package ? this.filterPrice(this.state.package, true) : 0
      this.totals.monthTotalSave = this.totals.marketprice - this.totals.total

      this.totals.totalAfter = this.totals.marketprice - this.state.tariff.price - this.totals.totalAfter
      if (this.state.tv_action) {
        let dc24 = 0
        let dc12 = 0
        let dc6 = 0

        let startDiscount = this.state.tariff['active_' + (this.state.tv_action === 1 ? 'nk' : 'bk') + '_discount'] === '6' ? 6 : 3

        if (this.state.tariff[this.state.tv_action === 1 ? ('nk' + this.activeDiscount) : ('bk' + this.activeDiscount)]) {
          dc6 = this.totals.monthTotalSave * startDiscount
        } else {
          this.totals.yearTotalSave += ((this.totals.monthTotalSave - this.state.tariff[this.state.tv_action === 1 ? ('nk' + this.activeDiscount) : ('bk' + this.activeDiscount)]) * startDiscount)
        }

        if (this.state.tariff[this.state.tv_action === 1 ? 'nk_24' : 'bk_24']) {
          dc24 = ((this.totals.monthTotalSave - this.state.tariff[this.state.tv_action === 1 ? ('nk' + this.activeDiscount) : ('bk' + this.activeDiscount)]) + this.state.tariff[this.state.tv_action === 1 ? 'nk_24' : 'bk_24']) * 12
        } else {
          this.totals.yearTotalSave += ((this.totals.monthTotalSave - this.state.tariff[this.state.tv_action === 1 ? ('nk' + this.activeDiscount) : ('bk' + this.activeDiscount)]) * 12)
        }
        if (this.state.tariff[this.state.tv_action === 1 ? 'nk_12' : 'bk_12']) {
          dc12 = ((this.totals.monthTotalSave - this.state.tariff[this.state.tv_action === 1 ? ('nk' + this.activeDiscount) : ('bk' + this.activeDiscount)]) + this.state.tariff[this.state.tv_action === 1 ? 'nk_12' : 'bk_12']) * (12 - startDiscount)
        } else {
          this.totals.yearTotalSave += ((this.totals.monthTotalSave - this.state.tariff[this.state.tv_action === 1 ? ('nk' + this.activeDiscount) : ('bk' + this.activeDiscount)]) * (12 - startDiscount))
        }

        this.totals.yearTotalSave += dc12 + dc24 + dc6
      } else {
        this.totals.yearTotalSave = this.totals.monthTotalSave * 24
      }
    },
    discountPrices (discount = '6') {
      // debugger
      let data = ''
      switch (discount) {
        case '24':
          data = ((this.totals.total + this.state.tariff[this.state.tv_action === 1 ? ('nk' + this.activeDiscount) : ('bk' + this.activeDiscount)]) - this.state.tariff[this.state.tv_action === 1 ? 'nk_24' : 'bk_24']).toLocaleString().replace(/\./g, ',') + ' € mtl. ' + this.discountString(discount)
          break
        case '12':
          data = ((this.totals.total + this.state.tariff[this.state.tv_action === 1 ? ('nk' + this.activeDiscount) : ('bk' + this.activeDiscount)]) - this.state.tariff[this.state.tv_action === 1 ? 'nk_12' : 'bk_12']).toLocaleString().replace(/\./g, ',') + ' € mtl. ' + this.discountString(discount)
          break
        default:
          data = (this.totals.total + this.state.tariff[(this.state.tv_action === 1 ? ('nk' + this.activeDiscount) : ('bk' + this.activeDiscount))]).toLocaleString().replace(/\./g, ',') + ' € mtl. ' + this.discountString(discount)
          break
      }
      return data
    },
    discountString (d) {
      return ' nach ' + ((d === '6' || d === '3')
        ? ((this.state.tariff[(this.state.tv_action === 1 ? 'nk_24' : 'bk_24')])
          ? 24
          : (this.state.tariff[(this.state.tv_action === 1 ? 'nk_12' : 'bk_12')]) ? 12 : this.state.tariff['active_' + (this.state.tv_action === 1 ? 'nk' : 'bk') + '_discount'])
        : d === '24' ? 12 : this.state.tariff['active_' + (this.state.tv_action === 1 ? 'nk' : 'bk') + '_discount']) + ' Monaten'
    }
  },
  data () {
    return {
      totals: {
        marketprice: 0,
        total: 0,
        addons: 0,
        monthTotalSave: 0,
        yearTotalSave: 0,
        totalAfter: 0
      }
    }
  },
  created () {
    this.calcPrices()
  }
}
</script>
